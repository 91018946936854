import React from "react"

import Title from 'components/Title'
import Constrain from 'components/Constrain'
import Video from 'components/Video'

import Container from 'components/Container'

import image from 'images/shop-management-video.png'
// import { useSiteMetadata } from "../../hooks/useSiteMetadata"

import Footer from "components/Footer"
import Header from "components/Header"

import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function Home() {

  const { t } = useTranslation()

  // const { title } = useSiteMetadata()

  return (
    <>
    <Header />
    <main>
      <Title 
        title={ t('Effortless shop management') } 
        subtitle={ t('Instantly know the status of every vehicle in your shop') }
        dark 
      />
      <Constrain>
        <Video poster={ image } src='https://autoservice.nyc3.cdn.digitaloceanspaces.com/media/shop-management-video.mp4' />
      </Constrain>

      <Container>

        <h2>{ t(`Daily schedule`) }</h2>

        <p>
          { t(`Easily see which vehicles are being worked on.`) }
        </p>

        <h2>{ t(`Weekly schedule`) }</h2>

        <p>
          { t(`Plan out your week.`) }
        </p>

      </Container>
  </main>
  <Footer />
  </>
  )
}
